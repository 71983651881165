var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("จัดการแอดมิน")]),_c('v-card',{staticClass:"mt-4 px-6 pt-6"},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"ค้นหา ...","dense":""},model:{value:(_vm.datatable.search),callback:function ($$v) {_vm.$set(_vm.datatable, "search", $$v)},expression:"datatable.search"}})],1),_c('v-card',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex pa-4"},[_c('v-btn',{attrs:{"disabled":_vm.datatable.selectedItems.length == 0},on:{"click":function($event){return _vm.deleteMultiple()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ 'name': 'invitation_create', params: { invitation_id: 'create' } })}}},[_vm._v("เชิญสมาชิก")])],1),_c('delay-datatable',{ref:"mainDatatable",attrs:{"show-select":"","headers":_vm.datatable.headers,"url":'/employee/admin/list',"queryParams":_vm.getDatatableQueryParams,"options":_vm.datatable.options,"isSaveState":true},on:{"update:options":function($event){return _vm.$set(_vm.datatable, "options", $event)},"loadState":_vm.loadDatatableState},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary","ripple":false}},on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false},on:{"input":select},model:{value:(isSelected),callback:function ($$v) {isSelected=$$v},expression:"isSelected"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.lastname)+" "),(_vm.$store.getters['app/GET_OWNER_ID'] == item.id)?_c('v-icon',{attrs:{"color":"#D4AF37"}},[_vm._v(_vm._s(_vm.mdiCrown))]):_vm._e()]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',_vm._l((item.roles),function(role){return _c('v-chip',{key:item.id + '_' + role.id},[_vm._v(_vm._s(role.name))])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'member_create', params: { admin_id: item.id }})}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$store.getters['auth/GET_ABILITY'].can('update', 'Employee') ? _vm.mdiPlaylistEdit : _vm.mdiEyeOutline))])],1),(_vm.$store.getters['app/GET_OWNER_ID'] != item.id)?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.$store.getters['app/GET_IS_OWNER']},on:{"click":function($event){return _vm.transferTo(item.id, item.name)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTransitTransfer))])],1):_vm._e(),(_vm.$store.getters['app/GET_OWNER_ID'] != item.id)?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.$store.getters['auth/GET_ABILITY'].can('delete', 'Employee')},on:{"click":function($event){return _vm.deleteSingle(item.id, item.name)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1):_vm._e()]}}],null,true),model:{value:(_vm.datatable.selectedItems),callback:function ($$v) {_vm.$set(_vm.datatable, "selectedItems", $$v)},expression:"datatable.selectedItems"}})],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.linkReqDialog.isShow),callback:function ($$v) {_vm.$set(_vm.linkReqDialog, "isShow", $$v)},expression:"linkReqDialog.isShow"}},[_c('v-card',[_c('v-card-title',[_vm._v("ลิ้งเชิญ")]),_c('div',{staticClass:"px-4"},[_c('copy-box',{attrs:{"dense":"","outlined":"","value":_vm.linkReqDialog.link}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.linkReqDialog.isShow = false}}},[_vm._v("ปิด")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }