<template>
    <div>
        <h2>จัดการแอดมิน</h2>

        <v-card class="mt-4 px-6 pt-6">            
            <v-text-field
              outlined
              v-model="datatable.search"
              placeholder="ค้นหา ..."
              dense
            ></v-text-field>
        </v-card>

        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>                    
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'invitation_create', params: { invitation_id: 'create' } })">เชิญสมาชิก</v-btn>     
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/employee/admin/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">            
            <v-simple-checkbox
              v-model="isSelected"
              color="primary"
              @input="select"
              :ripple="false"
            />
          </template>

          <template          
            v-slot:item.name="{ item }"
          >   
            {{ item.name }} {{ item.lastname }} <v-icon color="#D4AF37" v-if="$store.getters['app/GET_OWNER_ID'] == item.id">{{ mdiCrown }}</v-icon>
          </template>

          <template
            v-slot:item.roles="{ item }"
          >
            <v-chip-group>
              <v-chip v-for="role in item.roles"
                :key="item.id + '_' + role.id"
              >{{ role.name }}</v-chip>
            </v-chip-group>
          </template>

          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'member_create', params: { admin_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Employee') ? mdiPlaylistEdit : mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="transferTo(item.id, item.name)"
              :disabled="!$store.getters['app/GET_IS_OWNER']"
              v-if="$store.getters['app/GET_OWNER_ID'] != item.id"
            ><v-icon>{{ mdiTransitTransfer }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              v-if="$store.getters['app/GET_OWNER_ID'] != item.id"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Employee')"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        

        <v-dialog v-model="linkReqDialog.isShow" max-width="600">
          <v-card>
            <v-card-title>ลิ้งเชิญ</v-card-title>
            <div class="px-4">
              <copy-box
                dense
                outlined
                :value="linkReqDialog.link"
              ></copy-box>
            </div>
            <v-card-actions class="justify-end">
              <v-btn @click="linkReqDialog.isShow = false" color="primary">ปิด</v-btn>
            </v-card-actions>    
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { mdiCrown, mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit, mdiTransitTransfer } from '@mdi/js'
export default {
  data() {
    return {
      linkReqDialog: {
        isShow: false,
        link: null,
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'ชื่อแอดมิน',
            value: 'name',
          },
          {
            text: 'กลุ่มสิทธิ์',
            value: 'roles',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
      mdiCrown,
      mdiTransitTransfer,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  mounted() {
    if (!this.$route.query.uuid) return

    this.showLinkBox(this.$route.query.uuid)
  },
  methods: {
    showLinkBox(uuid) {
      this.linkReqDialog.link = window.location.host + '/join/' + uuid
      this.linkReqDialog.isShow = true
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteMultiple() {
      const adminNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const adminIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + adminNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/product/admin', { items: adminIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/employee/admin', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async transferTo(to, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะโอนสิทธิ์ ?',
        body:
          'คุณต้องการที่โอนสิทธิ์ความเป็นเจ้าของไปที่ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncPost('/employee/admin/transfer', { to: to })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>